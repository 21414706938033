import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '../../types';
import { ResponseState } from './types';
import { QuestionResponse } from '../../../types/questionResponse.types';

// initial state
const state: ResponseState = {
	datas: {
		assure: {
			assureId: null,
			nomFamille: null,
			prenom: null,
			nirOD: null,
			dateNaissance: null,
			sexe: null,
			consentementKit: null,
			consentementEval: null,
			telSms: null,
			indicTelSms: null,
			email: null,
			adresse: {
				chez: '',
				adresse: '' ,
				adresseCplt: '',
				codePostal: '',
				territoireHabitation:'',
				typeAdresse: '',
				ville: ''
			}
		},
		assureReponses: []
	}
};

export const actions: ActionTree<ResponseState, RootState> = {
	addResponse({ commit }, data:QuestionResponse) {
		commit('ADD_RESPONSE', data);
	},
	getResponseLocalStorage({ commit }) {
		commit('UPDATE_RESPONSE_LOCAL_STORAGE');
	},
	resetState({ commit }) {
		commit ('RESET_STATE');
	}
};

export const mutations: MutationTree<ResponseState> = {
	ADD_RESPONSE(state, data) {
		if(data.questionCode == 'ELIG001') {
			state.datas.assure.dateNaissance = data.dateNaissance;
		} else if(!['identity', 'adresse', 'coordonnees', 'verif'].includes(data.questionCode)) {
			if(state.datas.assureReponses.length > 0 && state.datas.assureReponses.find((element: QuestionResponse) => element.questionCode == data.questionCode)) {
				let repElig002 = [0];
				state.datas.assureReponses.forEach((element: QuestionResponse) => {
					if(data.questionCode == 'ELIG002' && (data.reponseId == 1 || data.reponseId == 2)) {
						repElig002 = data.reponseId;
						state.datas.assureReponses[0].reponseId = repElig002;
					}
					if(element.questionCode === data.questionCode) {
						element.reponseId = data.reponseId;
					}
				});
			} else {
				if(data.questionCode == 'ELIG002') {
					const repElig012 = {
						questionCode: 'ELIG012',
						reponseId: [0]
					};
					if(data.reponseId == 1) {
						repElig012.reponseId = [1];
					}
					if(data.reponseId == 2) {
						repElig012.reponseId = [2];
					}
					state.datas.assureReponses.push(repElig012);
				}
				state.datas.assureReponses.push(data);
			}
		}
		if(data.questionCode == 'identity') {
			state.datas.assure.prenom = data.prenom;
			state.datas.assure.nomFamille = data.nomFamille;
			state.datas.assure.dateNaissance = data.dateNaissance;
			state.datas.assure.nirOD = data.nirOD;
		} else if(data.questionCode == 'adresse') {
			state.datas.assure.adresse = data.adresse;
		} else if(data.questionCode == 'coordonnees') {
			state.datas.assure.telSms= data.telSms;
			state.datas.assure.email= data.email;
		}
		if(data.questionCode == 'verif') {
			state.datas.assure.consentementEval = data.consentementEval;
			state.datas.assure.consentementKit = data.consentementKit;
		}
	},
	UPDATE_RESPONSE_LOCAL_STORAGE(state) {
		state.datas = window.localStorage.responses.datas;
	},
	RESET_STATE(state) {
		state.datas = {
			assure: {
				assureId: null,
				nomFamille: null,
				prenom: null,
				nirOD: null,
				dateNaissance: null,
				sexe: null,
				consentementKit: null,
				consentementEval: null,
				telSms: null,
				indicTelSms: null,
				email: null,
				adresse: {
					chez: '',
					adresse: '' ,
					adresseCplt: '',
					codePostal: '',
					territoireHabitation:'',
					typeAdresse: '',
					ville: ''
				}
			},
			assureReponses: []
		};
	}
};

export const getters: GetterTree<ResponseState, RootState> = {
	getAllResponses(state) {
		return state;
	}
};

export const responses: Module<ResponseState, RootState> = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
