
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { themeColors } from '@/theme/colors';
	import AppImage from '@/components/image/image.vue';
	import RoundedButton from './roundedButton.vue';
	import { IMAGES } from '@/constants/image';

	@Component({
		components: {
			RoundedButton,
			AppImage
		}
	})
	export default class FloatButton extends Vue {
		@Prop()
		readonly textColor!: string;
		@Prop()
		readonly backgroundColor!: string;
		@Prop()
		readonly text!: string;

		get imageUrl() {
			return {
				arrowUpImage: IMAGES.ARROWUP
			};
		}

		get style() {
			return {
				textColor :this.textColor,
				backgroundColor: this.backgroundColor
			};
		}

		get themeColors() {
			return themeColors;
		}
	}
