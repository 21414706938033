export const MAINTENANCE_ENABLED = window.MAINTENANCE === 'true';
export const MAINTENANCE_TITLE =  window.MAINTENANCE_TITLE;
export const MAINTENANCE_MESSAGE = window.MAINTENANCE_MESSAGE;
export const STATIC_PATH = process.env.BASE_URL?.replace(/^\/$/, '') || '';
export const API_HOST = window.API_URL;
export const API_PATH = '/public/rest';
export const GRAVITEE_APIKEY = window.GRAVITEE_APIKEY;
export const API_ADDRESS_URL = window.API_ADDRESS_URL;
export const MOTIFS_INCIDENT = window.MOTIFS_INCIDENT;
export const MOTIF_INCIDENT_NON_RECU = window.MOTIF_INCIDENT_NON_RECU;
export const MOTIF_RESULTATS_NON_RECU = window.MOTIF_RESULTATS_NON_RECU;
export const NOMBRE_JOURS_OUVRES_INCIDENT = window.NOMBRE_JOURS_OUVRES_INCIDENT;
export const RESULTATS_DELIVERY_TIME = window.RESULTATS_DELIVERY_TIME;
export const KIT_DELIVERY_TIME = window.KIT_DELIVERY_TIME;
export const API_DROIT_ENABLED = window.API_DROIT_ENABLED === 'true';
export const NIR_FIRST_CHARACTER = window.NIR_FIRST_CHARACTER;
export const TENTATIVES_OTP = window.TENTATIVES_OTP;
// Liens
export const LINK_MTIST = window.LINK_MTIST;
export const LINK_MES_ANALYSES = window.LINK_MES_ANALYSES;
export const LINK_QUESTION_SEXUALITE = window.LINK_QUESTION_SEXUALITE;
export const LINK_SIDA_INFO_SERVICE = window.LINK_SIDA_INFO_SERVICE;
// Cookies
export const COOKIE_REQUIRED_DURATION = window.COOKIE_REQUIRED_DURATION;
export const COOKIE_ANALYTICS_DURATION = window.COOKIE_ANALYTICS_DURATION;
export const COOKIE_REQUIRED_UNIT = window.COOKIE_REQUIRED_UNIT;
export const COOKIE_ANALYTICS_UNIT = window.COOKIE_ANALYTICS_UNIT;
// Mode d'emploi
export const MANUAL_ISTU_ENABLED = window.MANUAL_ISTU_ENABLED;
export const MANUAL_ISTU_VIDEO = window.MANUAL_ISTU_VIDEO;
export const MANUAL_ISTU_DESCRIPTION = window.MANUAL_ISTU_DESCRIPTION;
export const MANUAL_ISTV_ENABLED = window.MANUAL_ISTV_ENABLED;
export const MANUAL_ISTV_VIDEO = window.MANUAL_ISTV_VIDEO;
export const MANUAL_ISTV_DESCRIPTION = window.MANUAL_ISTV_DESCRIPTION;

