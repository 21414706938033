
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { themeColors } from '@/theme/colors';
	import { IMAGES } from '@/constants/image';
	import AppImage from '@/components/image/image.vue';
	import FooterBar from '@cnamts/vue-dot/src/patterns/FooterBar';

	/** The application footer */
	@Component({
		components: {
			AppImage,
			FooterBar
		}
	})
	export default class AppFooter extends Vue {

		get themeColors() {
			return themeColors;
		}

		get imageUrl() {
			return {
				arrowUpImage: IMAGES.ARROWUP
			};
		}

		cookiesPageRoute = 'cookiesPage';

		mounted() {
			const lienGestionCookies = document.querySelector('a[href="/cookiesPage"]');
			this.cookiesPageRoute = '';
			lienGestionCookies?.addEventListener('click', this.handleCookieLinkClick);
		}

		handleCookieLinkClick() {
			this.$emit('show-cookies-page');
		}
	}
