/** Custom color theme (linked to ./colors.scss) */
export const themeColors = {
	YELLOW : '#F0B323',
	DARKBLUE: '#0C419A',
	LIGHTBLUE: '#CED9EB',
	WHITE: '#FFFFFF',
	GREEN: '#49A660',
	LIGHTGREEN: '#49A66033',
	BLACK: '#000000',
	LIGHTGRAY: '#F8F9FC',
	LIGHTGREY: '#E7ECF5',
	PINK: '#E01B84',
	LIGHTPINK: '#F9D1E6',
	RED: '#B33F2E'
};

export const stepperColors = {
	GREY: '#4654654',
	BLUE: '#0C419A',
	GREEN: '#006755'
};
