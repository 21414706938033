import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '../../types';
import { SuiviCommandeState } from './types';
import { SuiviCommande } from '@/types/commande.types';

// initial state
export const state: SuiviCommandeState = {
	datas: {
		numCommande: null,
		lastTwoCommandInEchec: null,
		commandeStatus: null,
		adresse: {
			chez: null,
			adresse: null,
			adresseCplt: null,
			codePostal: null,
			territoireHabitation:null,
			typeAdresse: null,
			ville: null
		},
		assure: {
			nomFamille: null,
			prenom: null,
			nirOD: null,
			dateNaissance: null
		},
		contactAssure: {
			telSms: null,
			indicTelSms: null,
			email: null
		},
		dateLivraison: null,
		motifIncident: null
	}
};

export const actions: ActionTree<SuiviCommandeState, RootState> = {
	storeSuiviCommande({ commit }, data:SuiviCommande) {
		commit('SAVE_SUIVI_COMMANDE', data);
	},
	storeContactAssure({ commit }, data) {
		commit('SAVE_CONTACT_ASSURE', data);
	},
	storeAdresseAssure({ commit }, data) {
		commit('UPDATE_ADDRESS_ASSURE', data);
	},
	resetSuiviCommande({ commit }) {
		commit ('RESET_STATE');
	}
};

export const mutations: MutationTree<SuiviCommandeState> = {
	SAVE_SUIVI_COMMANDE(state, datas) {
		state.datas.numCommande = datas.numCommande;
		state.datas.lastTwoCommandInEchec = datas.lastTwoCommandInEchec;
		state.datas.commandeStatus = datas.commandeStatus;
		state.datas.adresse = datas.adresse;
		state.datas.assure = datas.assure;
		state.datas.dateLivraison = datas.dateLivraison;
		state.datas.motifIncident = datas.motifIncident;
	},
	SAVE_CONTACT_ASSURE(state, data) {
		state.datas.contactAssure = data;
	},
	UPDATE_ADDRESS_ASSURE(state, data) {
		state.datas.adresse = data.adresse;
	},
	RESET_STATE(state) {
		state.datas = {
			numCommande: null,
			lastTwoCommandInEchec: null,
			commandeStatus: null,
			adresse: {
			chez: null,
				adresse: null,
				adresseCplt: null,
				codePostal: null,
				territoireHabitation:null,
				typeAdresse: null,
				ville: null
			},
			assure: {
				nomFamille: null,
				prenom: null,
				nirOD: null,
				dateNaissance: null
			},
			contactAssure: {
				telSms: null,
				indicTelSms: null,
				email: null
			},
			dateLivraison: null,
			motifIncident: null
		};
	}
};
export const getters: GetterTree<SuiviCommandeState, RootState> = {
	suiviCommande(state): SuiviCommandeState {
		return state.datas;
	}
};

export const suiviCommande: Module<SuiviCommandeState, RootState> = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
