
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';

	/** The application section */
	@Component
	export default class AppSection extends Vue {
		@Prop({ default: '' })
		readonly backgroundColor!: string;

		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		get sectionStyle() {
			return {
				backgroundColor: this.backgroundColor
			};
		}
	}
