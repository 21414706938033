
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { themeColors } from '@/theme/colors';
	import { Meta, MetaInfo } from '@/decorators';

	// Static import for faster rendering
	import { AppHeader, AppFooter } from '@/components/layout';
	import NotificationBar from '@cnamts/vue-dot/src/patterns/NotificationBar/NotificationBar.vue';
	import { mapActions, mapState } from 'vuex';
	import { Watch } from 'vue-property-decorator';
	import CookieBanner from '@cnamts/vue-dot/src/patterns/CookieBanner/CookieBanner.vue';
	import CookiesPage from '@cnamts/vue-dot/src/templates/CookiesPage/CookiesPage.vue';
	import { TypeEnum } from '@cnamts/vue-dot/src/templates/CookiesPage/CookiesInformation/TypeEnum';
	import { CookiesPageItems, Preferences } from '@cnamts/vue-dot/src/templates/CookiesPage/types';
	import Cookies from 'js-cookie';
	import { COOKIE_REQUIRED_DURATION, COOKIE_ANALYTICS_DURATION, COOKIE_REQUIRED_UNIT, COOKIE_ANALYTICS_UNIT } from '@/constants';
	import moment, { unitOfTime } from 'moment';

	/** App is the main component */
	@Component({
		components: {
			AppHeader,
			AppFooter,
			NotificationBar,
			CookiesPage,
			CookieBanner
		},
		computed: {
			...mapState('notification', ['notification'])
		},
		methods: {
			...mapActions('notification', ['clearNotification'])
		}
	})
	export default class App extends Vue {
		/** The meta informations of the route */
		/* istanbul ignore next */
		@Meta
		metaInfo(): MetaInfo {
			return {
				// If no sub-components specify a metaInfo.title, this title will be used
				title: 'Mon test IST',
				// All titles will be injected into this template
				titleTemplate: '%s | CNAM',
				meta: [
					{
						name: 'description',
						vmid: 'description', /** Used to avoid duplicated tags @see https://vue-meta.nuxtjs.org/faq/#unique-metadata */
						content: 'Mon test IST.'
					}
				]
			};
		}
		get themeColors() {
			return themeColors;
		}
		@Watch('notification')
		clearNotif() {
			if(this.notification && this.notification.type !== 'error') {
				setTimeout(() => {
					this.clearNotification();
				}, 5000);
			}
		}
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}

		activeCookieBanner = false;
		activeCookiePage = false;

		created() {
			this.activeCookieBanner = Boolean(!Cookies.get('cookie_agreed')) || Boolean(!Cookies.get('google_tag'));
		}

		mounted() {
			this.customizeCookieBanner();
		}

		updated() {
			this.customizeCookieBanner();
		}

		customizeCookieBanner() {
			const lienChoix = document.querySelector('.vd-cookie-banner-action-ctn .v-btn--router');
			lienChoix?.addEventListener('click', this.handleCookieBannerClick);
			document.querySelector('[aria-label="Continuer sans accepter"]')?.remove();
		}

		cookiesList: CookiesPageItems = {
			[TypeEnum.ESSENTIALS]: [
				{
					name: 'cookie_agreed',
					description: 'Permet de mémoriser le consentement du bandeau cookies.',
					conservation: '13 mois'
				}
			],
			[TypeEnum.ANALYTICS]: [
				{
					name: 'Google Tag Manager : cookies Campaign Manager',
					description: 'Ces cookies permettent d’établir des statistiques anonymes de nos campagnes de communication, via Google Tag Manager, pour évaluer leur diffusion et leur efficacité. Les données collectées par nos partenaires ne sont utilisées que pour répondre aux besoins de Mon Test IST et ne sont ni stockées ni transmises à des tiers non autorisés.',
					conservation: '13 mois'
				}
			]
		};

		expirationDate(cookieDuration: number, cookieDurationUnit: unitOfTime.Base) {
			return moment().add(cookieDuration, cookieDurationUnit).toDate();
		}

		handleCookieBannerClick(event: Event) {
			event.preventDefault();
			event.stopImmediatePropagation();
			this.activeCookiePage = true;
		}

		saveRequiredCookie(userChoice: string) {
			Cookies.set('cookie_agreed', 'true', { expires: this.expirationDate(Number(COOKIE_REQUIRED_DURATION), COOKIE_REQUIRED_UNIT as unitOfTime.Base) });
			Cookies.set('google_tag', userChoice, { expires: this.expirationDate(Number(COOKIE_ANALYTICS_DURATION), COOKIE_ANALYTICS_UNIT as unitOfTime.Base) });
			this.activeCookieBanner = false;
			this.activeCookiePage = false;
		}

		savePreferences(preferences: Preferences) {
			Cookies.set('cookie_agreed', 'true', { expires: this.expirationDate(Number(COOKIE_REQUIRED_DURATION), COOKIE_REQUIRED_UNIT as unitOfTime.Base) });
			Cookies.set('google_tag', String(preferences.analytics), { expires: this.expirationDate(Number(COOKIE_ANALYTICS_DURATION), COOKIE_ANALYTICS_UNIT as unitOfTime.Base) });
			this.activeCookieBanner && this.activeCookiePage ? this.$router.go(-1) : '';
			this.activeCookiePage = false;
			this.activeCookieBanner = false;
		}

		isHomePage() {
			return this.$route.path === '/';
		}
	}
