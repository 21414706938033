
    /* eslint-disable @typescript-eslint/no-explicit-any */
	/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import AppImage from '@/components/image/image.vue';

	/** The Stepper */
	@Component({
		components: {
			AppImage
		}
	})
	export default class StepperDesktop extends Vue {
		@Prop({ default: {} })
		readonly images: any;

		get imageUrl() {
			return this.images;
		}

		get steps() {
			return [
				{
					image: this.images.step1Image,
					description: 'En 5 minutes, je remplis les informations demandées'
				},
				{
					image: this.images.step2Image,
					description: 'Je renseigne l’adresse à laquelle je souhaite recevoir mon kit'
				},
				{
					image: this.images.step3Image,
					description: 'Je vérifie mes informations et je valide ma commande'
				}
			];
		}

		get lastStep() {
			return 'Votre kit de dépistage vous sera livré à l\'adresse renseignée dans une enveloppe neutre, sous 7 jours hors week-end et jours fériés.\nVous allez recevoir un message de confirmation par mail et par SMS.';
		}
	}
