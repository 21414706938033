
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { NavigationItem } from '@cnamts/vue-dot/src/patterns/HeaderBar/types';

	@Component({
		components: {}
	})
	export default class AppHeader extends Vue {
		get navigationItems(): NavigationItem[] | undefined {
			if (this.$maintenanceEnabled) {
				return undefined;
			}

			return [
				{
					label: 'Accueil',
					to: {
						name: 'home'
					}
				},
				{
					label: 'Commandez un kit',
					to: {
						name: 'preambule'
					}
				},
				{
					label: 'Suivi de commande',
					to: {
						name: 'tracking'
					}
				},
				{
					label: 'Mode d\'emploi du test',
					to: {
						name: 'manual'
					}
				},
				{
					label: 'Questions fréquentes',
					to: {
						name: 'faq'
					}
				}
			];
		}
	}
