import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { RootState } from './types';

import { questionnaire } from '@/store/modules/questionnaire/questionnaireStore';
import { responses } from '@/store/modules/responses/responsesStore';
import { commande } from '@/store/modules/commande/commandeStore';
import { suiviCommande } from '@/store/modules/SuiviCommande/SuiviCommandeStore';
import { notification } from '@cnamts/vue-dot/src/modules/notification';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'ist',
  storage: window.localStorage
});

/** See https://vuex.vuejs.org/fr/getting-started.html for help */
const storeOptions: StoreOptions<RootState> = {
	strict: true,
	state: {},
	// See https://vuex.vuejs.org/guide/modules.html for more info on modules
	modules: {
		questionnaire,
		responses,
		commande,
		suiviCommande,
		notification
	},
	plugins: [vuexPersist.plugin]
};

export const store = new Vuex.Store<RootState>(storeOptions);
