import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '../../types';
import { QuestionnaireState } from './types';
import { getQuestionnaireApi } from '@/api/questions/questionnaireApi';

export const state: QuestionnaireState = {
	datas: []
};

export const actions: ActionTree<QuestionnaireState, RootState> = {
	async getQuestionnaire({ commit }) {
		getQuestionnaireApi().then(datas => {
			commit('UPDATE_QUESTIONNAIRE', datas);
			return datas;
		});
	}
};

export const mutations: MutationTree<QuestionnaireState> = {
	UPDATE_QUESTIONNAIRE(state, datas) {
		state.datas = datas.data;
	}
};

export const getters: GetterTree<QuestionnaireState, RootState> = {
	getAllQuestions(state): QuestionnaireState {
		return state;
	}
};

export const questionnaire: Module<QuestionnaireState, RootState> = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
