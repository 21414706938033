import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '../../types';
import { CommandeState } from './types';
import { Commande } from '@/types/commande.types';

// initial state
export const state: CommandeState = {
	numCommande: null
};

export const actions: ActionTree<CommandeState, RootState> = {
	saveCommande({ commit }, data:Commande) {
		commit('SAVE_COMMANDE', data);
	},
	resetCommande({ commit }) {
		commit ('RESET_STATE');
	},
	reOrder({ commit }, data:Commande) {
		commit('RE_ORDER', data);
	}
};

export const mutations: MutationTree<CommandeState> = {
	SAVE_COMMANDE(state, datas) {
		state.numCommande = datas.numCommande;
	},
	RESET_STATE(state) {
		state.numCommande = null;
	},
	RE_ORDER(state, datas) {
		state.numCommande = datas.numCommande;
	}
};
export const getters: GetterTree<CommandeState, RootState> = {
	getCommande(state): CommandeState {
		return state;
	}
};

export const commande: Module<CommandeState, RootState> = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
};
