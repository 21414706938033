/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosResponse } from 'axios';
import { API_ADDRESS_URL, API_HOST, GRAVITEE_APIKEY } from '@/constants';
import { router } from '@/router';

/** The axios instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: API_HOST,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'x-api-key': GRAVITEE_APIKEY
	}
});

/** The axios instance for Apis Tiers (without Header) */
const instanceApiTiers = axios.create({
	withCredentials: false,
	baseURL: API_ADDRESS_URL
});

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if ((error.response?.status >= 500 && error.response?.status < 600)
		|| (error.response?.status === 0)
		|| (error.response?.status === 401)
		|| (error.response?.status === 403)) {
			const serverRoute = {
				path: `/indisponible${error.response.status}`
			};
			router.push(serverRoute);
		}
		return Promise.reject(error);
	}
);

export { instance as axios, instanceApiTiers as axiosApiTiers };
export { AxiosResponse };
