
	import { HighlightTextType } from '@/types';
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';

	/** The application section */
	@Component
	export default class HighlightText extends Vue {
		@Prop({ default: '' })
		readonly backgroundColor: HighlightTextType|undefined;
		@Prop({ default: '' })
		readonly textColor: HighlightTextType|undefined;

		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		get style() {
			return {
				backgroundColor: this.backgroundColor,
				textColor: this.textColor
			};
		}
	}
