import Vue from 'vue';

// Polyfill for vue-input-facade on IE
// (not included in core-js)
import 'custom-event-polyfill';

// Register class components hooks
import Component from 'vue-class-component';

Component.registerHooks([
	'beforeRouteEnter',
	'beforeRouteLeave',
	'beforeRouteUpdate'
]);

// Import plugins
import { vuetify } from './plugins/vuetify';
import './plugins/vue-dot';
import './plugins/form-builder';

// Import the theme styles
import '@/theme/colors.scss';
import '@/theme/breakpoints.scss';
import '@/theme/styles.scss';

// Import application parts
import App from './App.vue';
import { router } from './router';
import { store } from './store';

import VueMask from 'v-mask';
Vue.use(VueMask);

import { MAINTENANCE_ENABLED } from './constants';

// Remove "tip" from browser console
Vue.config.productionTip = false;
// Add global var for maintenance
Vue.prototype.$maintenanceEnabled = MAINTENANCE_ENABLED;

// clean local storage after 7 days
import moment from 'moment';

const dateNow = moment();
if(window.localStorage.getItem('lastVisit') !== null) {
	const dateCheck = moment(window.localStorage.getItem('lastVisit'));
	if(dateNow.diff(dateCheck, 'days') > 7) {
		window.localStorage.clear();
		store.dispatch('responses/resetState');
		window.localStorage.setItem('lastVisit', moment(dateNow).format());
	} else {
		window.localStorage.setItem('lastVisit', moment(dateNow).format());
	}
} else {
	window.localStorage.setItem('lastVisit', moment(dateNow).format());
}

// Create main Vue instance
new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount('#app'); // Mount it on #app (in public/index.html)
