
	/* eslint-disable @typescript-eslint/no-explicit-any */
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { themeColors } from '@/theme/colors';

	@Component({
		name: 'app-button'
	})
	export default class RoundedButton extends Vue {
		/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
		@Prop() readonly textColor!: string;
		@Prop() readonly backgroundColor!: string;
		@Prop()	readonly disabled!: boolean;
		@Prop({ default: 260 }) readonly width!: number;
		@Prop({ default: 44 }) readonly height!: number;
		@Prop()	readonly link!: string;
		@Prop()	readonly route!: string;
		@Prop({ default: false })	readonly bordered!: boolean;

		get style(): { [key: string]: string} {
			return {
				color: this.computedBordered ? themeColors.DARKBLUE : themeColors.WHITE,
				backgroundColor: this.computedBordered ? themeColors.WHITE : themeColors.DARKBLUE,
				width: '260px',
				height: '44px'
			};
		}

		get computedBordered(): boolean {
			return this.bordered !== false;
		}
	}
