
	/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
	/* eslint-disable @typescript-eslint/no-explicit-any */
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { IMAGES } from '@/constants/image';
	import { themeColors } from '@/theme/colors';
	import AppImage from '@/components/image/image.vue';

	type InfoTextIcon = 'info-primary' | 'info-primary-fill' | 'info-error' | 'warning' | 'info-women';

	/** The application section */
	@Component ({
		components: {
			AppImage
		}
	})
	export default class InfoText extends Vue {
		@Prop({ default: 'info-primary' })
		readonly icon!: InfoTextIcon;
		@Prop({ default: '' })
		readonly frameClass!: string;
		@Prop({ default: {} })
		readonly frameStyle!: any;
		@Prop({ default: false })
		readonly iconWrap!: boolean;
		@Prop({ default: {} })
		readonly iconStyle!: any;
		@Prop({ default: false })
		readonly iconVerticalCenter!: any;

		overrideFrameStyle: any = {
			borderStyle: 'solid',
			borderColor: themeColors.DARKBLUE,
			borderRadius: '4px',
			padding: '0 16px 0 16px'
		};

		overrideIconStyle: any = {
			marginTop: '16px',
			marginRight: '10px',
			width: '20px',
			height: '20px'
		};

		get themeColors() {
			return themeColors;
		}

		get imageUrl() {
			return {
				'info-primary': IMAGES.INFO_PRIMARY,
				'info-error': IMAGES.INFO_ERROR,
				'warning': IMAGES.WARNINGICON,
				'info-women': IMAGES.INFOWOMEN
			};
		}

		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		created() {
			Object.keys(this.frameStyle).forEach((key: string) => {
				this.overrideFrameStyle[key] = this.frameStyle[key];
			});

			Object.keys(this.iconStyle).forEach((key: string) => {
				this.overrideIconStyle[key] = this.iconStyle[key];
			});
		}
	}
