
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import AppSection from '@/components/layout/AppSection.vue';
	import HighlightText from '@/components/texts/HiglightText.vue';
	import AppImage from '@/components/image/image.vue';
	import RoundedButton from '@/components/button/roundedButton.vue';
	import StepperDesktop from '@/components/stepper/stepperDesktop.vue';
	import FloatButton from '@/components/button/floatButton.vue';
	import { IMAGES } from '@/constants/image';
	import { themeColors } from '@/theme/colors';
	import InfoText from '@/components/texts/InfoText.vue';
	import { LINK_QUESTION_SEXUALITE, LINK_SIDA_INFO_SERVICE } from '@/constants';

	/** Home page */
	@Component({
		components : {
			AppSection,
			HighlightText,
			AppImage,
			RoundedButton,
			StepperDesktop,
			FloatButton,
			InfoText
		}
	})

	export default class Home extends Vue {
		get liens() {
			return {
				lienTrouvezLaboratoire: 'https://www.sante.fr/recherche/trouver/laboratoires',
				lienConsulterProfessionnel: `${LINK_QUESTION_SEXUALITE}trouver-un-professionnel`,
				lienChlamydiaEnSavoirPlus: 'https://www.ameli.fr/assure/sante/themes/infection-chlamydia/comprendre-infection',
				lienGonocoqueEnSavoirPlus: 'https://www.ameli.fr/assure/sante/themes/mst-ist/maladies-infections-sexuellement-transmissibles',
				lienTrouvezCentre: `${LINK_SIDA_INFO_SERVICE}annuaire/`

			};
		}

		get imageUrl() {
			return {
				girlImage: IMAGES.GIRL,
				boyImage: IMAGES.BOY,
				istPostImage: IMAGES.ISTPOST,
				chlamyquoiImage: IMAGES.CHLAMYQUOI,
				chlamydiaeImage: IMAGES.CHLAMYDIAE,
				gonoquoiImage: IMAGES.GONOQUOI,
				gonocoqueImage: IMAGES.GONOCOQUE,
				separatorImage: IMAGES.SEPARATOR,
				preservatifImage: IMAGES.PRESERVATIF,
				step1Image: IMAGES.STEP1,
				step2Image: IMAGES.STEP2,
				step3Image: IMAGES.STEP3,
				arrowImage: IMAGES.ARROWRIGHT,
				arrowUpImage: IMAGES.ARROWUP,
				plusImage: IMAGES.PLUS,
				moinsImage: IMAGES.MOINS,
				loupeImage: IMAGES.LOUPE
			};
		}

		get themeColors() {
			return themeColors;
		}

		get frequentQuestions() {
			return [
				{
					title: 'Comment soigner une IST ?',
					text: 'Votre médecin vous prescrira des antibiotiques pour traiter l’infection sexuellement transmissible dépistée. Pour que le traitement soit efficace, il est nécessaire de le suivre jusqu’au bout (...)',
					href: '/faq'
				},
				{
					title: 'Qui est concerné par ce dépistage ?',
					text: 'Il est recommandé qu’au moins un dépistage de l’infection à Chlamydia et à gonocoque soit réalisé chez les femmes sexuellement actives de 15 à 25 ans inclus.',
					href: '/faq'
				}
			];
		}
	}
